.App {
  height: 100vh;
  box-sizing: border-box;
}

@media(min-width: 1024px) {
  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 50px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(196, 196, 196);
  }
}
